<template>
  <div>
    <!-- Filter -->
    <b-card
      footer-tag="footer"
      class="mb-1 card-filter-container"
    >
      <b-row>
        <b-col
          sm="12"
          md="6"
          lg="6"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_from')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.startDate"
              :max="filter.endDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_from')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.startTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
        <b-col
          sm="12"
          md="6"
          lg="6"
          class="card-filter-item"
        >
          <b-input-group class="mb-1">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('date_to')"
              />
            </b-input-group-prepend>
            <b-form-datepicker
              v-model="filter.endDate"
              :min="filter.startDate"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="en"
            />
          </b-input-group>
          <b-input-group class="mb-1-sm mb-1-md">
            <b-input-group-prepend is-text>
              <span
                style="min-width: 95px;"
                v-text="$t('time_to')"
              />
            </b-input-group-prepend>
            <b-form-timepicker
              v-model="filter.endTime"
              show-seconds
              locale="en"
            />
          </b-input-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="12"
          lg="12"
          class="card-filter-item mt-1"
        >
          <b-button
            variant="primary float-right"
            @click="loadData(1)"
            v-text="$t('search')"
          />
        </b-col>
      </b-row>
    </b-card>
    <!-- Table Container Card -->
    <b-overlay
      :show="loading"
      opacity="0.80"
      rounded="sm"
    >
      <div class="card">
        <div class="card-body">
          <div class="vgt-wrap">
            <div class="vgt-inner-wrap">
              <div class="vgt-responsive game-history-table">
                <table class="vgt-table bordered">
                  <thead>
                    <tr>
                      <th
                        class="vgt-left-align"
                      >
                        <span v-text="$t('memo')" />
                      </th>
                      <th
                        class="vgt-left-align"
                        style="width: 150px;"
                      >
                        <span v-text="$t('type')" />
                      </th>
                      <th
                        class="vgt-left-align"
                        style="width: 150px;"
                      >
                        <span v-text="$t('table_name')" />
                      </th>
                      <th
                        class="vgt-left-align"
                        style="width: 150px;"
                      >
                        <span v-text="$t('username')" />
                      </th>
                      <th
                        class="vgt-left-align"
                        style="width: 150px;"
                      >
                        <span v-text="$t('user_id')" />
                      </th>
                      <th
                        class="vgt-left-align"
                        style="width: 120px;"
                      >
                        <span v-text="$t('created_at')" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="item in items"
                      :key="item.id"
                    >
                      <!-- Username -->
                      <td
                        class="vgt-left-align"
                        style="vertical-align: middle;"
                      >
                        <span v-text="item.memo" />
                      </td>
                      <!-- type -->
                      <td
                        class="vgt-left-align"
                        style="vertical-align: middle;"
                      >
                        <span v-text="item.type" />
                      </td>
                      <!-- tableName -->
                      <td
                        class="vgt-left-align"
                        style="vertical-align: middle;"
                      >
                        <span v-text="item.tableName" />
                      </td>
                      <!-- userUsername -->
                      <td
                        class="vgt-left-align"
                        style="vertical-align: middle;"
                      >
                        <span v-text="item.userUsername" />
                      </td>
                      <!-- userId -->
                      <td
                        class="vgt-left-align"
                        style="vertical-align: middle;"
                      >
                        <span v-text="item.userId" />
                      </td>
                      <!-- Created At -->
                      <td
                        class="vgt-left-align"
                        style="vertical-align: middle;"
                      >
                        <span v-text="item.createdAtDate" /><br>
                        <span v-text="item.createdAtTime" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <custom-pagination
            v-model="filter.page"
            :total-items="totalItems"
            :per-page="perPage"
            :current-page="filter.page"
            @page-changed="loadData"
            @change-item-per-page="changeItemPerPage"
          />
        </div>
      </div>
    </b-overlay>

  </div>
</template>

<script>

import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupPrepend,
  BFormDatepicker,
  BFormTimepicker,
  BButton,
} from 'bootstrap-vue'

import AvatarActivity from '@/models/AvatarActivity'

import 'vue-good-table/dist/vue-good-table.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import CustomPagination from '@/components/table/CustomPagination.vue'
import { EventBus } from '../../../eventbus'

export default {
  name: 'ReportSeamlessLog',
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupPrepend,
    BFormDatepicker,
    BFormTimepicker,
    BButton,
    CustomPagination,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      items: [],
      loading: true,
      perPage: 1,
      totalItems: 0,
      filter: {
        page: typeof this.$route.query.page !== 'undefined' ? this.$route.query.page : 1,
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
      },
      currentFilter: {
        page: 1,
        startDate: typeof this.$route.query.startDate !== 'undefined' ? this.$route.query.startDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() - 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() - 86400000).getDate())}`,
        endDate: typeof this.$route.query.endDate !== 'undefined' ? this.$route.query.endDate : `${(new Date().getFullYear())}-${(new Date((new Date()).getTime() + 86400000).getMonth() + 1)}-${(new Date((new Date()).getTime() + 86400000).getDate())}`,
        startTime: typeof this.$route.query.startTime !== 'undefined' ? this.$route.query.startTime : '00:00:00',
        endTime: typeof this.$route.query.endTime !== 'undefined' ? this.$route.query.endTime : '23:59:59',
      },
    }
  },
  mounted() {
    this.loadData()
  },
  methods: {
    async changeItemPerPage(itemPerPage) {
      this.perPage = itemPerPage
      this.loadData(1)
    },
    async loadData(page = 1) {
      this.loading = true
      const searchParams = {}
      this.filter.page = page
      this.filter.perPage = this.perPage
      Object.keys(this.filter).forEach(e => {
        if (typeof this.filter[e] === 'number') {
          if (this.filter[e] > 0) {
            searchParams[e] = this.filter[e]
          }
        } else if (typeof this.filter[e] === 'string') {
          if (this.filter[e] !== null && this.filter[e].length > 0) {
            searchParams[e] = this.filter[e]
          }
        }
      })

      this.$router.replace({ query: searchParams })
      const response = await AvatarActivity.list(searchParams)
      if (response.data.status) {
        this.items = response.data.data
        this.totalItems = response.data.meta.pagination.total
        this.perPage = response.data.meta.pagination.per_page
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: this.$t(response.data.message),
          },
        }, {
          position: 'top-center',
        })
      }
      EventBus.$emit('getMyUpdatedUserData')
      this.loading = false
    },
  },
}
</script>
